import React from 'react';
import { Modal, Input, Button } from 'antd';
import TimeInput from '../../Components/TimeInput';
import styles from './style.module.scss';

const NotificacaoRestauranteModal = ({ open, modalTitle, modalAction, loading, titulo, texto, horario, setTitulo, setTexto, handleTimeChange, handleFormSubmit, setOpen }) => {
    return (
        <Modal
            title={<p>{modalTitle}</p>}
            footer={
                <div>
                    <Button type="primary" onClick={handleFormSubmit}>
                        {modalAction}
                    </Button>
                    <Button type="secondary" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                </div>
            }
            loading={loading}
            open={open}
            onCancel={() => setOpen(false)}
            confirmLoading={loading}
        >
            <div className={styles.containerModal}>
                <div>
                    <label>Titulo</label>
                    <Input
                        value={titulo}
                        placeholder='Desconto Dia dos Namorados'
                        onChange={(e) => setTitulo(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
                <div>
                    <label>Texto</label>
                    <Input
                        value={texto}
                        placeholder='Venha comemorar com seu amor'
                        onChange={(e) => setTexto(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
                <div>
                    <label>Horário <strong>(hora que vai ser enviada a notificação)</strong></label>
                    <TimeInput value={horario} onChange={handleTimeChange} />
                </div>
            </div>
        </Modal>
    );
};

export default NotificacaoRestauranteModal;
