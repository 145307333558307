import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Breadcrumb, Table, Button, Modal, Input, Col, message, Select, Popconfirm } from 'antd';
import { IoAdd } from "react-icons/io5";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient';
import moment from 'moment';
import 'moment/locale/pt-br';

const Usuarios = () => {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Cadastrar Usuário');
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [status, setStatus] = useState('ativo');
  const [saldo, setSaldo] = useState(0);
  const [modalAction, setModalAction] = useState('Cadastrar');
  const [editRecordId, setEditRecordId] = useState(null)
  const [data, setData] = useState([]);
  const [senha, setSenha] = useState('12345678');
  const [tipo, setTipo] = useState('');
  console.log('tipo', tipo)
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    nome: '',
    status: '',
    tipo: '',
  });

  useEffect(() => {
    fetchDataUsuários();
  }, []);

  const fetchDataUsuários = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/usuarios')
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar os usuários!');
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setModalTitle('Editar Usuário');
    setModalAction('Salvar');
    setEditRecordId(record.id);
    setNome(record.nome);
    setSobrenome(record.sobrenome);
    setEmail(record.email);
    setSenha(record.senha);
    setTipo(record.tipo);
    setCpf(record.cpf);
    setTelefone(record.telefone);
    setStatus(record.status);
    setSaldo(record.saldo);
    setOpen(true);
  };

  function mapType(value) {
    switch (value) {
      case 0:
        return 'Cliente';
      case 1:
        return 'Restaurante';
      case 2:
        return 'Administrador';
      default:
        return 'Desconhecido';
    }
  }

  const formatarTelefone = (telefone) => {
    if (!telefone) return '';

    const numeros = telefone.replace(/\D/g, '');
    if (numeros.length === 11) {
      return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (numeros.length === 10) {
      return numeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return numeros;
  };

  const formatarDocumento = (documento) => {
    if (!documento) return '';
    const apenasNumeros = documento.replace(/\D/g, '');
    if (apenasNumeros.length === 11) {
      return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (apenasNumeros.length === 14) {
      return apenasNumeros.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
    return documento;
  };

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const handleDelete = async (id) => {
    try {
      const authToken = localStorage.getItem('RangoPassToken');
      await api.delete(`/admin/usuarios/${id}`, {
      }, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      message.success('Usuário deletado!');
      fetchDataUsuários();
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      message.error('Erro ao deletar usuário!');
    }
  };

  const mapStatus = (status) => {
    switch (status) {
      case 'ativo':
        return 'Ativo';
      case 'deletado':
        return 'Deletado';
      default:
        return 'Desconhecido';
    }
  };

  const columns = [

    {
      title: 'Usuário',
      dataIndex: 'usuario',
      key: 'usuario',
      render: (text, record) => `${record.nome} ${record.sobrenome}`,
      width: 150,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 80,
      render: (text, record) => mapType(record.tipo), // Aplica a função mapType para mostrar o tipo correto
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      render: (text) => formatarTelefone(text || ''), // Adiciona um valor padrão vazio se text for null
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      render: (text) => formatarDocumento(text || ''), // Adiciona um valor padrão vazio se text for null
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
    },
    {
      title: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => formatarValor(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: {
        showTitle: false,
      },
      render: (status) => mapStatus(status), // Usa a função mapStatus para renderizar o status
    },

    {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'acoes',
      ellipsis: {
        showTitle: false,
      },
      render: (address, record) => (
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<AiOutlineEdit />}
            size={10}
            style={{ marginRight: '8px' }}
            onClick={() => handleEdit(record)} // Chama handleEdit com o registro atual
          />
          <Popconfirm
            title="Tem certeza que deseja excluir este usuario?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="primary"
              shape="circle"
              icon={<AiOutlineDelete />}
              size={10}
            />
          </Popconfirm>
        </Col>
      ),
    },
  ];

  const showLoading = () => {
    setOpen(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleFormSubmit = async () => {
    if (!nome || !sobrenome || !email || !cpf || !telefone) {
      message.error('Por favor, preencha todos os campos!');
      return;
    }

    setLoading(true);

    try {
      const authToken = localStorage.getItem('RangoPassToken');
      const dadosUsuario = {
        nome,
        sobrenome,
        email,
        senha,
        tipo: Number(tipo),  // Certifique-se de que tipo é um número
        cpf,
        telefone,
        status,
        saldo: Number(saldo),
      };

      console.log("Dados a serem enviados:", dadosUsuario);  // Verifique os dados antes de enviar

      let response;
      let responsePut;

      if (modalAction === 'Cadastrar') {
        response = await api.post('/admin/usuarios', dadosUsuario, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        message.success('Usuário cadastrado com sucesso!');
      } else if (modalAction === 'Salvar') {
        responsePut = await api.put(`/admin/usuarios/${editRecordId}`, dadosUsuario, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        console.log('Response da API:', responsePut);
        message.success('Usuário atualizado com sucesso!');
      }
      fetchDataUsuários();
      setOpen(false);
    } catch (error) {
      console.error(error.message);
      message.error('Erro ao cadastrar/atualizar usuário!');
    }

    setLoading(false);
  };


  const tipoOption = [
    { value: '0', label: 'Cliente' },
    { value: '1', label: 'Restaurante' },
    { value: '2', label: 'Administrador' },
  ];

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    applyFilters(filterValues);
  };

  const applyFilters = (filterValues) => {
    const { nome, tipo, status } = filterValues;
    let filtered = [...data];
    if (nome) { filtered = filtered.filter(item => item.nome.toLowerCase().includes(nome.toLowerCase())) }
    if (tipo) { filtered = filtered.filter(item => item.tipo.toString() === tipo) }
    if (status) { filtered = filtered.filter(item => item.status === status) }
    setFilteredData(filtered);
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: <a>Gerenciamento</a>,
          },
          {
            // href: '',
            title: (
              <>
                <span>Usuários</span>
              </>
            ),
          },
        ]}
      />

      <Button type="secundary" shape="round" icon={<IoAdd />} size={10} onClick={showLoading}>
        Usuários
      </Button>

      <div className={styles.filters}>
        <div className={styles.inputsContainer}>
          <label>Restaurante</label>
          <Input
            placeholder="Filtrar por nome do restaurante"
            value={filters.nome}
            onChange={(e) => handleFilter({ ...filters, nome: e.target.value })}
            style={{ width: `100%` }}
          />
        </div>
        <div className={styles.inputsContainer}>
          <label>Tipo</label>
          <Select
            placeholder="Filtrar por tipo"
            value={filters.tipo}
            onChange={(value) => handleFilter({ ...filters, tipo: value })}
            style={{ width: `100%` }}
          >
            <Option value="">Todos</Option>
            <Option value="0">Cliente</Option>
            <Option value="1">Restaurante</Option>
            <Option value="2">Administrador</Option>
          </Select>
        </div>
        <div className={styles.inputsContainer}>
          <label>Status</label>
          <Select
            placeholder="Filtrar por status"
            value={filters.status}
            onChange={(value) => handleFilter({ ...filters, status: value })}
            style={{ width: `100%` }}
          >
            <Option value="">Todos</Option>
            <Option value="ativo">Ativo</Option>
            <Option value="inativo">Inativo</Option>
          </Select>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        scroll={{ x: 'max-content' }}
      />

      <Modal
        title={<p>{modalTitle}</p>}
        footer={
          <div>
            <Button type="primary" onClick={handleFormSubmit}>
              {modalAction}
            </Button>
            <Button type="secondary" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </div>
        }
        loading={loading}
        open={open} // Corrigido para visible ao invés de open
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <div className={styles.containerModal}>
          <div>
            <label>Nome</label>
            <Input
              value={nome}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                setNome(newValue);
              }}
              placeholder="Digite o nome..."
              style={{ width: '100%' }}
              type="text"
            />
          </div>
          <div>
            <label>Sobrenome</label>
            <Input
              value={sobrenome}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                setSobrenome(newValue);
              }}
              placeholder="Digite o sobrenome..."
              style={{ width: '100%' }}
              type="text"
            />
          </div>
          <div>
            <label>E-mail</label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Digite o email..."
              style={{ width: '100%' }}
              type="email"
            />
          </div>
          <div>
            <label>Telefone</label>
            <Input
              value={formatarTelefone(telefone)}
              onChange={(e) => setTelefone(e.target.value)}
              placeholder="Digite o telefone..."
              style={{ width: '100%' }}
              type="tel"
            />
          </div>
          <div>
            <label>CPF</label>
            <Input
              value={formatarDocumento(cpf)}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, '').slice(0, 11);
                setCpf(newValue);
              }}
              placeholder="Digite o cpf..."
              style={{ width: '100%' }}
              type="text"
            />
          </div>

          <div>
            <label>Tipo</label>
            <Select
              className={styles.select}
              value={tipo}
              placeholder="Selecione..."
              onChange={(value) => {
                setTipo(Number(value));  // Converter valor para número
                console.log("Tipo selecionado:", Number(value));  // Verifique o valor no console
              }}
              style={{ width: '100%' }}
            >
              {tipoOption.map((tipo) => (
                <Select.Option key={tipo.value} value={tipo.value}>
                  {tipo.label}
                </Select.Option>
              ))}
            </Select>

          </div>

          {modalAction === 'Salvar' && (
            <div>
              <label>Saldo</label>
              <Input
                value={saldo}
                onChange={(e) => setSaldo(e.target.value)}
                placeholder="Digite o valor..."
                style={{ width: '100%' }}
              />
            </div>
          )}
        </div>
      </Modal>

    </div>
  );
};

export default Usuarios;
