import React from 'react';
import { useAuth } from './contexts/AuthContext';

export const useMenuData = () => {
  const { user } = useAuth();

  const menuData = [

    {
      key: 'dashboard',
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path d='M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69ZM12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z' fill='#9E9E9E' />
        </svg>
      ),
      title: 'Dashboard',
      isVisible: true,
      requiredUserLevel: 2,
      to: '/',
    },
    {
      key: 'financeiro',
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
            fill='#9E9E9E'
          />
        </svg>
      ),
      title: 'Financeiro',
      isVisible: true,
      hasDividerBefore: false,
      requiredUserLevel: 2,
      children: [
        { key: 'pagamentos', title: 'Pagamentos', to: '/pagamentos', isVisible: true, requiredUserLevel: 2, },
        { key: 'saques', title: 'Saques', to: '/saques', isVisible: true, requiredUserLevel: 2, },
      ],
    },

    {
      key: 'gerenciamento',
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M13.5117 1.7561H5.51172C4.98129 1.7561 4.47258 1.96682 4.09751 2.34189C3.72243 2.71696 3.51172 3.22567 3.51172 3.7561V19.7561C3.51172 20.2865 3.72243 20.7952 4.09751 21.1703C4.47258 21.5454 4.98129 21.7561 5.51172 21.7561H17.5117C18.0422 21.7561 18.5509 21.5454 18.9259 21.1703C19.301 20.7952 19.5117 20.2865 19.5117 19.7561V7.7561M13.5117 1.7561L19.5117 7.7561M13.5117 1.7561L13.5117 7.7561H19.5117M15.5117 12.7561H7.51172M15.5117 16.7561H7.51172M9.51172 8.7561H7.51172'
            stroke='#9E9E9E'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ), // Substitua por seu ícone de Bases
      title: 'Gerenciamento',
      isVisible: true,
      requiredUserLevel: 2,
      children: [
        { key: 'checkins', title: 'Checkins', to: '/checkins', isVisible: true, requiredUserLevel: 2 },
        { key: 'pedidos', title: 'Pedidos', to: '/pedidos', isVisible: true, requiredUserLevel: 2 },
        { key: 'restaurantes', title: 'Restaurantes', to: '/restaurantes', isVisible: true, requiredUserLevel: 2 },
        { key: 'produtos', title: 'Produtos', to: '/produtos', isVisible: true, requiredUserLevel: 2 },
        { key: 'categorias', title: 'Categorias', to: '/categorias', isVisible: true, requiredUserLevel: 2 },
        { key: 'usuarios', title: 'Usuários', to: '/usuarios', isVisible: true, requiredUserLevel: 2 },
        { key: 'cupons', title: 'Cupons', to: '/cupons', isVisible: true, requiredUserLevel: 2 },
        { key: 'tiporecebimento', title: 'Tipo de Recebimentos', to: '/tiporecebimento', isVisible: true, requiredUserLevel: 2 },
        { key: 'notificacao', title: 'Notificação', to: '/notificacao', isVisible: true, requiredUserLevel: 2 },
      ],
    },
    {
      key: 'restaurante',
      icon: (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M13.5117 1.7561H5.51172C4.98129 1.7561 4.47258 1.96682 4.09751 2.34189C3.72243 2.71696 3.51172 3.22567 3.51172 3.7561V19.7561C3.51172 20.2865 3.72243 20.7952 4.09751 21.1703C4.47258 21.5454 4.98129 21.7561 5.51172 21.7561H17.5117C18.0422 21.7561 18.5509 21.5454 18.9259 21.1703C19.301 20.7952 19.5117 20.2865 19.5117 19.7561V7.7561M13.5117 1.7561L19.5117 7.7561M13.5117 1.7561L13.5117 7.7561H19.5117M15.5117 12.7561H7.51172M15.5117 16.7561H7.51172M9.51172 8.7561H7.51172'
            stroke='#9E9E9E'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),

      title: 'Restaurante',
      isVisible: true,
      requiredUserLevel: 1,
      children: [
        { key: 'checkinsrestaurante', title: 'Checkins', to: '/checkinsrestaurante', isVisible: true, requiredUserLevel: 1 },
        { key: 'pedidosrestaurante', title: 'Pedidos', to: '/pedidosrestaurante', isVisible: true, requiredUserLevel: 1 },
        { key: 'produtosrestaurante', title: 'Produtos', to: '/produtosrestaurante', isVisible: true, requiredUserLevel: 1 },
        { key: 'cuponsrestaurante', title: 'Cupons', to: '/cuponsrestaurante', isVisible: true, requiredUserLevel: 1 },
        { key: 'notificacaorestaurante', title: 'Notificações', to: '/notificacaorestaurante', isVisible: true, requiredUserLevel: 1 },
        { key: 'perfilrestaurante', title: 'Meu Restaurante', to: '/perfilrestaurante', isVisible: true, requiredUserLevel: 1 },
      ],
    },
  ];

  return menuData;
};
