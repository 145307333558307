import React from 'react';
import { Modal, Input } from 'antd';
import styles from './style.module.scss';
import moment from 'moment';
import 'moment/locale/pt-br';

const PedidoRestauranteModal = ({
  open,
  loading,
  modalTitle,
  codigoPedido,
  statusPedido,
  nomeUsuario,
  createdAt,
  itens, // Recebe a lista de itens do pedido
  valor,
  onCancel,
}) => {
  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const formatarData = (data) => {
    return moment(data).format('DD/MM/YY, HH:mm');
  };

  const valorFormatado = formatarValor(valor);
  const dataFormatada = formatarData(createdAt);

  // Função para pegar o último trecho do código após o hífen e deixar em maiúsculas
  const formatarCodigoPedido = (codigo) => {
    const partes = codigo.split('-');
    return partes[partes.length - 1].toUpperCase(); // Pega o último trecho e deixa em maiúsculas
  };

  return (
    <Modal
      title={<p>{modalTitle}</p>}
      footer={null}
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      <div className={styles.containerModal}>
        <span className={styles.codigo}>{formatarCodigoPedido(codigoPedido)}</span>
        <span className={`${styles.status} ${statusPedido === 'Finalizado' ? styles.statusAtivo : styles.statusPendente}`}>{statusPedido}</span>
        <div>
          <label>Nome</label>
          <Input value={nomeUsuario} disabled style={{ width: '100%' }} />
        </div>
        <div>
          <label>Criado em</label>
          <Input value={dataFormatada} disabled style={{ width: '100%' }} />
        </div>
        <div>
          <label>Valor Total</label>
          <Input value={valorFormatado} disabled style={{ width: '100%' }} />
        </div>
        {/* Mapeia os itens do pedido e exibe cada produto */}
        <div>
          <label>Produtos</label>
          {itens.map((item, index) => (
            <div key={index} className={styles.produtoItem}>
              <p>{item.produto} - Quantidade: {item.quantidade}</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PedidoRestauranteModal;
