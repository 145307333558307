import React, { useState, useEffect } from 'react';
import { Breadcrumb, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { IoAdd } from "react-icons/io5";
import moment from 'moment';
import { api } from '../../services/apiClient';
import { useAuth } from '../../contexts/AuthContext';
import Filtro from '../../Components/Filtro';
import NotificacaoRestauranteTable from '../../Components/NotificacaoRestauranteTable';
import NotificacaoRestauranteModal from '../../Components/NotificacaoRestauranteModal';
import styles from './style.module.scss';

const NotificacaoRestaurante = () => {
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('Adicionar Notificação');
    const [loading, setLoading] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [horario, setHorario] = useState(null);
    const [texto, setTexto] = useState('');
    const [modalAction, setModalAction] = useState('Adicionar');
    const [data, setData] = useState([]);
    const [editRecordId, setEditRecordId] = useState(null);

    const handleTimeChange = (e) => {
        setHorario(e.target.value);
    };

    useEffect(() => {
        fetchDataNotificacao();
    }, []);

    const fetchDataNotificacao = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/restaurante/notificacao/${user.restaurante.id}`);
            const notifications = response.data.message;
            setData(notifications);
        } catch (error) {
            message.error('Erro ao buscar as notificações!');
        }
        setLoading(false);
    };

    const handleEdit = (record) => {
        setModalTitle('Editar Notificação');
        setModalAction('Salvar');
        setTitulo(record.titulo);
        setEditRecordId(record.id);
        setTexto(record.texto);
        setHorario(record.horario ? moment(record.horario, "HH:mm") : null);
        setOpen(true);
    };

    const handleFormSubmit = async () => {
        if (!titulo || !texto || !horario) {
            message.error('Por favor, preencha todos os campos!');
            return;
        }

        setLoading(true);

        try {
            const authToken = localStorage.getItem('RangoPassToken');
            const dataAtual = moment().format('YYYY-MM-DD');
            const horarioFormatado = moment(`${dataAtual} ${horario}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD/HH:mm');

            const dadosNotificacao = {
                restauranteId: user.restaurante.id,
                titulo,
                texto,
                horario: horarioFormatado
            };

            let response;
            if (modalAction === 'Adicionar') {
                response = await api.post('/restaurante/notificacao', dadosNotificacao, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                message.success('Notificação cadastrada com sucesso!');
            } else if (modalAction === 'Salvar') {
                response = await api.put(`/restaurante/notificacao/${editRecordId}/${user.restaurante.id}`, dadosNotificacao, {
                    headers: { Authorization: `Bearer ${authToken}` }
                });
                message.success('Notificação atualizada com sucesso!');
            }

            fetchDataNotificacao();
            setOpen(false);
        } catch (error) {
            message.error('Erro ao cadastrar/atualizar Notificação!');
        }

        setLoading(false);
    };

    const handleFilter = (filters) => {
        console.log('Filtrando com:', filters);
    };

    return (
        <div className={styles.container}>
            <Breadcrumb
                items={[
                    {
                        title: (
                            <Link to='/'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M11.2 3.32275...' fill='#757575' />
                                </svg>
                            </Link>
                        ),
                    },
                    { title: <a>Gerenciamento</a> },
                    { title: <span>Notificação</span> },
                ]}
            />

            <Button
                type="secondary"
                shape="round"
                icon={<IoAdd />}
                size="large"
                onClick={() => {
                    setModalTitle('Adicionar Notificação');
                    setModalAction('Adicionar');
                    setTitulo('');
                    setTexto('');
                    setHorario(null);
                    setEditRecordId(null);
                    setOpen(true);
                }}
            >
                Notificação
            </Button>


            <Filtro
                onFilter={handleFilter}
                options={[
                    { value: 'ativo', label: 'Ativo' },
                    { value: 'encerrado', label: 'Encerrado' },
                    { value: 'pausado', label: 'Pausado' },
                ]}
                showUsuarioInput={true}
                showTipoSelect={true}
                showButtonFiltrar={true}
            />

            <NotificacaoRestauranteTable data={data} handleEdit={handleEdit} />

            <NotificacaoRestauranteModal
                open={open}
                modalTitle={modalTitle}
                modalAction={modalAction}
                loading={loading}
                titulo={titulo}
                texto={texto}
                horario={horario}
                setTitulo={setTitulo}
                setTexto={setTexto}
                handleTimeChange={handleTimeChange}
                handleFormSubmit={handleFormSubmit}
                setOpen={setOpen}
            />
        </div>
    );
};

export default NotificacaoRestaurante;
