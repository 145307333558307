import React, { useState, useEffect } from 'react';
import { Input, Select, Button, Space, Breadcrumb, Image, message, TimePicker, Switch, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import moment from 'moment/moment';

const PerfilRestaurante = () => {
    const { user } = useAuth();
    const [operacao, setOperacao] = useState('');
    const [chavePix, setChavePix] = useState('');
    const [agencia, setAgencia] = useState('');
    const [agenciaDg, setAgenciaDg] = useState('');
    const [conta, setConta] = useState('');
    const [categoria, setCategoria] = useState('');
    const [endereco, setEndereco] = useState({});
    const [dados_banco, setDados_Banco] = useState({});
    const [bancoList, setBancoList] = useState([]);
    const [tipoList, setTipoList] = useState([]);
    const [categoriaList, setCategoriaList] = useState([]);
    const [tipo_recebimento, setTipoRecebimento] = useState([]);
    const [nome, setNome] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [email, setEmail] = useState('');
    const [documento, setDocumento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [favorecido, setFavorecido] = useState('');
    const [cpfcnpjFavorecido, setCpfcnpjFavorecido] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [statusRestaurante, setStatusRestaurante] = useState('ativo');
    const [banco, setBanco] = useState(null);
    const [imagemUrl, setImagemUrl] = useState('');
    const [aberto1, setAberto1] = useState(false);
    const [abre1, setAbre1] = useState(null);
    const [fecha1, setFecha1] = useState(null);
    const [aberto2, setAberto2] = useState(false);
    const [abre2, setAbre2] = useState(null);
    const [fecha2, setFecha2] = useState(null);
    const [aberto3, setAberto3] = useState(false);
    const [abre3, setAbre3] = useState(null);
    const [fecha3, setFecha3] = useState(null);
    const [aberto4, setAberto4] = useState(false);
    const [abre4, setAbre4] = useState(null);
    const [fecha4, setFecha4] = useState(null);
    const [aberto5, setAberto5] = useState(false);
    const [abre5, setAbre5] = useState(null);
    const [fecha5, setFecha5] = useState(null);
    const [aberto6, setAberto6] = useState(false);
    const [abre6, setAbre6] = useState(null);
    const [fecha6, setFecha6] = useState(null);
    const [aberto7, setAberto7] = useState(false);
    const [abre7, setAbre7] = useState(null);
    const [fecha7, setFecha7] = useState(null);
    const [feriadoAberto, setFeriadoAberto] = useState(false);
    const [feriadoAbre, setFeriadoAbre] = useState(null);
    const [feriadoFecha, setFeriadoFecha] = useState(null);
    const [local, setLocal] = useState(true);
    const [retirada, setRetirada] = useState(true);

    const handleCheckboxChange = (e, type) => {
        const isChecked = e.target.checked;

        if (type === 'local') {
            if (isChecked) {
                setLocal(true);
            } else {
                // Se "local" for desmarcado, certifique-se de que "retirada" esteja marcado
                setLocal(false);
                if (!retirada) {
                    setRetirada(true); // Marca "retirada" se estiver desmarcado
                }
            }
        } else if (type === 'retirada') {
            if (isChecked) {
                setRetirada(true);
            } else {
                // Se "retirada" for desmarcado, certifique-se de que "local" esteja marcado
                setRetirada(false);
                if (!local) {
                    setLocal(true); // Marca "local" se estiver desmarcado
                }
            }
        }
    };

    const fetchRestaurante = async () => {
        try {
            const response = await api.get(`/restaurante?id=${user.restaurante.id}`);
            const restauranteData = response.data.message[0];

            if (restauranteData) {
                console.log('restauranteData', restauranteData);

                // Atualize os estados com os dados do restaurante
                setNome(restauranteData.nome);
                setNomeFantasia(restauranteData.nomeFantasia || '');
                setEmail(restauranteData.email || '');
                setDocumento(restauranteData.valorDoc || '');
                setTelefone(restauranteData.telefone || '');
                setCategoria(restauranteData.categoriaId || '');
                setOperacao(restauranteData.dados_banco.operacao)

                // Atualiza o estado do endereço com o primeiro objeto do array
                const endereco = restauranteData.enderecos?.[0] || {};
                setEndereco(endereco);

                setFavorecido(restauranteData.favorecido || '');
                setCpfcnpjFavorecido(restauranteData.documento || '');

                const dados_banco = restauranteData.dados_banco?.[0] || {};
                setDados_Banco(dados_banco);

                const tipo_recebimento = restauranteData.tipo_recebimento?.[0] || {};
                setTipoRecebimento(tipo_recebimento);

                setStatusRestaurante(restauranteData.status || 'ativo');
                const imageUrl = restauranteData.arquivos && restauranteData.arquivos[0]
                    ? `https://rangopassbucket.s3.amazonaws.com/${restauranteData.arquivos[0].key}`
                    : '';
                setImagemUrl(imageUrl);
            } else {
                console.error('Restaurante não encontrado');
                message.error('Restaurante não encontrado.');
            }
        } catch (error) {
            console.error('Erro ao carregar dados do restaurante:', error);
            message.error('Erro ao carregar dados do restaurante.');
        }
    };

    useEffect(() => {
        fetchDataHorarios();
    }, []);

    const fetchDataHorarios = async () => {
        try {
            const response = await api.get(`/restaurante/horarios/${user.restaurante.id}`);
            const horarios = response.data.message;

            setAberto1(horarios.aberto1 === "fechado" ? false : true);
            setAbre1(horarios.abre1 ? moment(horarios.abre1, 'HH:mm') : null);
            setFecha1(horarios.fecha1 ? moment(horarios.fecha1, 'HH:mm') : null);
            setAberto2(horarios.aberto2 === "fechado" ? false : true);
            setAbre2(horarios.abre2 ? moment(horarios.abre2, 'HH:mm') : null);
            setFecha2(horarios.fecha2 ? moment(horarios.fecha2, 'HH:mm') : null);
            setAberto3(horarios.aberto3 === "fechado" ? false : true);
            setAbre3(horarios.abre3 ? moment(horarios.abre3, 'HH:mm') : null);
            setFecha3(horarios.fecha3 ? moment(horarios.fecha3, 'HH:mm') : null);
            setAberto4(horarios.aberto4 === "fechado" ? false : true);
            setAbre4(horarios.abre4 ? moment(horarios.abre4, 'HH:mm') : null);
            setFecha4(horarios.fecha4 ? moment(horarios.fecha4, 'HH:mm') : null);
            setAberto5(horarios.aberto5 === "fechado" ? false : true);
            setAbre5(horarios.abre5 ? moment(horarios.abre5, 'HH:mm') : null);
            setFecha5(horarios.fecha5 ? moment(horarios.fecha5, 'HH:mm') : null);
            setAberto6(horarios.aberto6 === "fechado" ? false : true);
            setAbre6(horarios.abre6 ? moment(horarios.abre6, 'HH:mm') : null);
            setFecha6(horarios.fecha6 ? moment(horarios.fecha6, 'HH:mm') : null);
            setAberto7(horarios.aberto7 === "fechado" ? false : true);
            setAbre7(horarios.abre7 ? moment(horarios.abre7, 'HH:mm') : null);
            setFecha7(horarios.fecha7 ? moment(horarios.fecha7, 'HH:mm') : null);
            setFeriadoAberto(horarios.feriadoAberto === "fechado" ? false : true);
            setFeriadoAbre(horarios.feriadoAbre ? moment(horarios.feriadoAbre, 'HH:mm') : null);
            setFeriadoFecha(horarios.feriadoFecha ? moment(horarios.feriadoFecha, 'HH:mm') : null);

        } catch (error) {
            console.error('Erro ao buscar dados de horarios:', error);
            message.error('Erro ao buscar horarios!');
        }
    };

    const handleSaveHorarios = () => {
        const horarios = {
            restauranteId: user.restaurante.id,
            aberto1: aberto1,
            abre1: abre1 ? abre1.format('HH:mm') : null,
            fecha1: fecha1 ? fecha1.format('HH:mm') : null,
            aberto2: aberto2,
            abre2: abre2 ? abre2.format('HH:mm') : null,
            fecha2: fecha2 ? fecha2.format('HH:mm') : null,
            aberto3: aberto3,
            abre3: abre3 ? abre3.format('HH:mm') : null,
            fecha3: fecha3 ? fecha3.format('HH:mm') : null,
            aberto4: aberto4,
            abre4: abre4 ? abre4.format('HH:mm') : null,
            fecha4: fecha4 ? fecha4.format('HH:mm') : null,
            aberto5: aberto5,
            abre5: abre5 ? abre5.format('HH:mm') : null,
            fecha5: fecha5 ? fecha5.format('HH:mm') : null,
            aberto6: aberto6,
            abre6: abre6 ? abre6.format('HH:mm') : null,
            fecha6: fecha6 ? fecha6.format('HH:mm') : null,
            aberto7: aberto7,
            abre7: abre7 ? abre7.format('HH:mm') : null,
            fecha7: fecha7 ? fecha7.format('HH:mm') : null,
            feriadoAberto: feriadoAberto,
            feriadoAbre: feriadoAbre ? feriadoAbre.format('HH:mm') : null,
            feriadoFecha: feriadoFecha ? feriadoFecha.format('HH:mm') : null,
        };

        console.log('horarios', horarios);

        api.put(`/restaurante/horarios/${user.restaurante.id}`, horarios)
            .then(response => {
                message.success('Horarios atualizados com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar os horarios:', error);
                message.error('Erro ao atualizar os horarios.');
            });
    };

    const fetchBancos = async () => {
        try {
            const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
            setBancoList(response.data);
        } catch (error) {
            console.error('Erro ao buscar os bancos:', error);
        }
    };

    const fetchDataCategoria = async () => {
        try {
            const response = await api.get('/categorias');
            setCategoriaList(response.data.message);
        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            message.error('Erro ao buscar as categorias!');
        }
    };

    useEffect(() => {
        fetchBancos();
        fetchRestaurante();
        fetchDataCategoria();
    }, [user]);

    const handleSave = () => {
        const restaurante = {
            nome,
            nomeFantasia,
            email,
            valorDoc: documento,
            telefone,
            categoria,
            cep: endereco.cep,
            logradouro: endereco.logradouro,
            numero: endereco.numero,
            complemento: endereco.complemento,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            uf: endereco.uf,
            favorecido,
            documento: cpfcnpjFavorecido,
            dados_banco: [
                {
                    operacao,
                    chavePix,
                    agencia,
                    agenciaDg,
                    conta,
                    nomeBanco: banco,
                },
            ],
            tipo_recebimento: tipo_recebimento,
            status: statusRestaurante,
            local,
            retirada
        };
        console.log('restaurante', restaurante)

        handleSaveHorarios()

        api.put(`/restaurante/editar/${user.restaurante.id}`, restaurante)
            .then(response => {
                message.success('Dados do restaurante atualizado com sucesso!');
                // window.location.href = '/restaurantes'
            })
            .catch(error => {
                console.error('Erro ao salvar dados do restaurante:', error);
                message.error('Erro ao salvar dados do restaurante.');
            });
    };


    useEffect(() => {
        setTipoList([
            { value: 'pix', titulo: 'Pix' },
            { value: 'corrente', titulo: 'Conta Corrente' },
            { value: 'poupanca', titulo: 'Conta Poupança' },
        ]);
    }, []);


    const handleTipoChange = (value) => {
        setOperacao(value);
        setCategoria(value);
        setDados_Banco(prevState => ({
            ...prevState,
            operacao: value, // Corrigido de "operacao" para "tipo"
            chavePix: value === 'pix' ? prevState.chavePix : '', // Corrigido de "chave_pix" para "chavePix"
            agencia: value !== 'pix' ? prevState.agencia : '',
            agenciaDg: value !== 'pix' ? prevState.agenciaDg : '',
            conta: value !== 'pix' ? prevState.conta : '',
        }));
    };

    const handleEnderecoChange = (field, value) => {
        setEndereco(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const buscarCep = async (cep) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.data.erro) {
                message.error('CEP não encontrado.');
                return;
            }

            // Atualiza o estado com os dados retornados pela API
            setEndereco(prevState => ({
                ...prevState,
                logradouro: response.data.logradouro,
                bairro: response.data.bairro,
                cidade: response.data.localidade,
                uf: response.data.uf,
            }));
        } catch (error) {
            console.error('Erro ao buscar o CEP:', error);
            message.error('Erro ao buscar o CEP.');
        }
    };

    const handleCepChange = (e) => {
        const cepValue = e.target.value;
        handleEnderecoChange('cep', cepValue);

        // Se o CEP tiver 8 dígitos, faz a consulta
        if (cepValue.length === 8) {
            buscarCep(cepValue);
        }
    };


    return (
        <div className={styles.container}>
            <Breadcrumb
                items={[
                    {
                        title: (
                            <Link to='/'>
                                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                                        fill='#757575'
                                    />
                                </svg>
                            </Link>
                        ),
                    },
                    { title: <a>Gerenciamento</a> },
                    { title: <span>Dados do Restaurante</span> },
                ]}
            />
            <div className={styles.imagem}>
                {imagemUrl && (
                    <Image
                        width={200}
                        src={imagemUrl}
                        className={styles.imagemRestaurante}
                        preview={false}
                        alt="Imagem do Restaurante"
                    />
                )}
            </div>
            <div className={styles.section}>
                <h2>Dados da Empresa</h2>
                <div className={styles.row}>
                    <div>
                        <label>Nome</label>
                        <Input
                            value={nome || ''}
                            onChange={(e) => setNome(e.target.value)}
                            placeholder="Digite o nome do restaurante..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Nome Fantasia</label>
                        <Input
                            value={nomeFantasia || ''}
                            onChange={(e) => setNomeFantasia(e.target.value)}
                            placeholder="Digite o nome fantasia do restaurante..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>CNPJ</label>
                        <Input
                            value={documento || ''}
                            onChange={(e) => setDocumento(e.target.value)}
                            placeholder="Digite o CNPJ do restaurante..."
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <label>Telefone</label>
                        <Input
                            value={telefone || ''}
                            onChange={(e) => setTelefone(e.target.value)}
                            placeholder="Digite o telefone do restaurante..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>E-mail</label>
                        <Input
                            value={email || ''}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Digite o telefone do restaurante..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Categoria</label>
                        <Select
                            value={categoria || 'pix'}
                            onChange={handleTipoChange}
                            options={categoriaList.map(categoria => ({
                                value: categoria.id,
                                label: categoria.titulo,
                            }))}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <h2>Endereço</h2>
                <div className={styles.row}>
                    <div>
                        <label>CEP</label>
                        <Input
                            value={endereco.cep || ''}
                            onChange={handleCepChange}
                            placeholder="Digite o CEP..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Logradouro</label>
                        <Input
                            value={endereco.logradouro || ''}
                            onChange={(e) => handleEnderecoChange('logradouro', e.target.value)}
                            placeholder="Digite o logradouro..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Número</label>
                        <Input
                            value={endereco.numero || ''}
                            onChange={(e) => handleEnderecoChange('numero', e.target.value)}
                            placeholder="Digite o número..."
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <label>Complemento</label>
                        <Input
                            value={endereco.complemento || ''}
                            onChange={(e) => handleEnderecoChange('complemento', e.target.value)}
                            placeholder="Digite o complemento..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Bairro</label>
                        <Input
                            value={endereco.bairro || ''}
                            onChange={(e) => handleEnderecoChange('bairro', e.target.value)}
                            placeholder="Digite o bairro..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Cidade</label>
                        <Input
                            value={endereco.cidade || ''}
                            onChange={(e) => handleEnderecoChange('cidade', e.target.value)}
                            placeholder="Digite a cidade..."
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <label>Estado</label>
                        <Input
                            value={endereco.uf || ''}
                            onChange={(e) => handleEnderecoChange('uf', e.target.value)}
                            placeholder="Digite o estado..."
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <h2>Dados Bancários</h2>
                <div className={styles.row}>
                    <div>
                        <label>Tipo</label>
                        <Select
                            value={dados_banco.operacao}
                            onChange={handleTipoChange}
                            options={tipoList.map(operacao => ({
                                value: operacao.value,
                                label: operacao.titulo,
                            }))}
                            style={{ width: '100%' }}
                        />
                    </div>
                    {operacao === 'pix' && (
                        <div>
                            <label>Chave Pix</label>
                            <Input
                                value={dados_banco.chavePix || ''}
                                onChange={(e) => setChavePix(e.target.value)}
                                placeholder="Digite a chave Pix..."
                                style={{ width: '100%' }}
                            />
                        </div>
                    )}
                    {operacao !== 'pix' && (
                        <>
                            <div>
                                <label>Banco</label>
                                <Select className={styles.select} value={dados_banco.nomeBanco} placeholder='Selecione...' onChange={(value) => setBanco(value)} style={{ width: '100%' }}>
                                    {bancoList.map((banco) => (
                                        <Select.Option value={banco.fullName}>{banco.fullName}</Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <label>Agência</label>
                                <Input
                                    value={dados_banco.agencia || ''}
                                    onChange={(e) => setAgencia(e.target.value)}
                                    placeholder="Digite a agência..."
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <label>Agência (Dígito)</label>
                                <Input
                                    value={dados_banco.agenciaDg || ''}
                                    onChange={(e) => setAgenciaDg(e.target.value)}
                                    placeholder="Digite o dígito da agência..."
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div>
                                <label>Conta</label>
                                <Input
                                    value={dados_banco.conta || ''}
                                    onChange={(e) => setConta(e.target.value)}
                                    placeholder="Digite a conta..."
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </>
                    )}

                </div>
            </div>
            <div className={styles.section}>
                <h2>Horário de Funcionamento</h2>
                <div className={styles.horariosSemana}>
                    <Checkbox
                        checked={local}
                        onChange={(e) => handleCheckboxChange(e, 'local')}
                    >
                        Comer no local
                    </Checkbox>
                    <Checkbox
                        checked={retirada}
                        onChange={(e) => handleCheckboxChange(e, 'retirada')}
                    >
                        Retirada
                    </Checkbox>
                </div>
                <div className={styles.horariosSemana}>
                    <div>
                        <div className={styles.dias}>
                            <h3>Segunda-Feira</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto1}
                                onChange={setAberto1}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre1}
                                onChange={setAbre1}
                                disabled={!aberto1}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha1}
                                onChange={setFecha1}
                                disabled={!aberto1}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Terça-Feira</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto2}
                                onChange={setAberto2}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre2}
                                onChange={setAbre2}
                                disabled={!aberto2}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha2}
                                onChange={setFecha2}
                                disabled={!aberto2}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Quarta-Feira</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto3}
                                onChange={setAberto3}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre3}
                                onChange={setAbre3}
                                disabled={!aberto3}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha3}
                                onChange={setFecha3}
                                disabled={!aberto3}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Quinta-Feira</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto4}
                                onChange={setAberto4}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre4}
                                onChange={setAbre4}
                                disabled={!aberto4}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha4}
                                onChange={setFecha4}
                                disabled={!aberto4}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.horariosSemana}>
                    <div>
                        <div className={styles.dias}>
                            <h3>Sexta-Feira</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto5}
                                onChange={setAberto5}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre5}
                                onChange={setAbre5}
                                disabled={!aberto5}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha5}
                                onChange={setFecha5}
                                disabled={!aberto5}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Sábado</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto6}
                                onChange={setAberto6}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre6}
                                onChange={setAbre6}
                                disabled={!aberto6}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha6}
                                onChange={setFecha6}
                                disabled={!aberto6}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Domingo</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={aberto7}
                                onChange={setAberto7}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={abre7}
                                onChange={setAbre7}
                                disabled={!aberto7}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={fecha7}
                                onChange={setFecha7}
                                disabled={!aberto7}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.dias}>
                            <h3>Feriado</h3>
                            <Switch
                                checkedChildren="aberto"
                                unCheckedChildren="fechado"
                                checked={feriadoAberto}
                                onChange={setFeriadoAberto}
                            />
                        </div>
                        <div className={styles.Inputs}>
                            <TimePicker
                                format="HH:mm"
                                value={feriadoAbre}
                                onChange={setFeriadoAbre}
                                disabled={!feriadoAberto}
                            />
                            :
                            <TimePicker
                                format="HH:mm"
                                value={feriadoFecha}
                                onChange={setFeriadoFecha}
                                disabled={!feriadoAberto}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <Space>
                    <Link to='/restaurantes'>
                        <Button type="secondary">
                            Cancelar
                        </Button>
                    </Link>
                    <Button type="primary" onClick={handleSave}>Salvar</Button>
                </Space>
            </div>
        </div>
    );
};

export default PerfilRestaurante;
