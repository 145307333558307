import React from 'react';
import { Table, Button } from 'antd';
import { AiTwotoneEdit } from "react-icons/ai";
import moment from 'moment';

const NotificacaoRestauranteTable = ({ data, handleEdit }) => {
    const columns = [
        {
            title: 'Titulo',
            dataIndex: 'titulo',
            key: 'titulo',
            render: (text) => <a>{text}</a>,
            width: 150,
        },
        {
            title: 'Texto',
            dataIndex: 'texto',
            key: 'texto',
            width: 80,
        },
        {
            title: 'Horário',
            dataIndex: 'horario',
            key: 'horario',
            ellipsis: {
                showTitle: false,
            },
            render: (text) => moment(text).format('DD/MM/YY, HH:mm'),
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) => (
                <Button type="tertiary" icon={<AiTwotoneEdit />} size={10} onClick={() => handleEdit(record)} />
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 'max-content' }}
        />
    );
};

export default NotificacaoRestauranteTable;
